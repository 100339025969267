<template>
  <!-- parent table -->
  <v-data-table
    :headers="tableLabels"
    :items="items"
    :items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    hide-default-footer
    disable-sort
    :show-select="true"
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
  >
    <template v-slot:header="{ props }">
      <th class="select-all">
        <v-simple-checkbox
          v-if="!flagNotData && list.length && !isDisableCheckBox"
          :value="isSelectedAllParent(list)"
          @click="updateAllCheckbox(list, !isSelectedAllParent(list))"
        ></v-simple-checkbox>

        <v-simple-checkbox
          v-if="!flagNotData && list.length && isDisableCheckBox"
          :value="isSelectedAllParent(list)"
        ></v-simple-checkbox>
      </th>
    </template>

    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="list && list.length > 0">
        <tbody
          class="tableRow"
          :class="{ borderRow: ENV_OBAYASHI }"
          v-for="item in list"
          :key="item.work_result_id"
        >
          <tr :class="item.state_handling_flag == 1 ? 'grey lighten-1' : ''">
            <td class="start">
              <v-row>
                <v-col cols="12" sm="3">
                  <!-- toggle icon -->
                  <v-icon @click.stop="toggleShowChildren(item.work_result_id)">
                    {{
                      isShowChildren[item.work_result_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-right"
                    }}
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="3">
                  <!-- checkbox -->
                  <v-simple-checkbox
                    v-if="!isTableTypeAll"
                    :value="isSelectedParent(item.work_result_id)"
                    @input="updateCheckbox(item, $event, true)"
                    :disabled="isDisableCheckBox"
                  ></v-simple-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <!-- checkbox -->
                  <v-simple-checkbox
                    v-if="!isTableTypeAll"
                    :value="isSelected(item.work_result_id)"
                    @input="updateCheckbox(item, $event, null, item)"
                    :disabled="isDisableCheckBox"
                  ></v-simple-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn small icon @click="$emit('addNewChild', item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
            <td class="col-status">
              <div
                v-for="(status, index) in approveStatus"
                :key="`status_${status}_${index}_1`"
              >
                <v-chip
                  @click="$emit('openPopupRatify', item)"
                  v-if="status.id === item.work_approval_flag"
                  class="ma-2"
                  small
                  :color="`${status.class}`"
                  dark
                  >{{ status.name }}
                  {{ getTotalApproval(item.total_approval) }}</v-chip
                >
              </div>
            </td>
            <td @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)">
              {{ item.company_name }}
            </td>
            <td @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)">
              {{ convertDate(item.work_date) }}
            </td>
            <td @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)">
              {{ item.job_name }}
            </td>
            <td @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)">
              {{ convertString(item.location_name, 16) }}
            </td>
            <td @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)">
              <table>
                <tr>
                  <td>
                    <template
                      v-if="
                        SCHEDULE_WARNING.hot_work_flag.id === item.hot_work_flag
                      "
                    >
                      <div
                        :class="`warning-icon ${SCHEDULE_WARNING.hot_work_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.hot_work_flag.name }}
                      </div>
                    </template>
                    <template
                      v-if="
                        SCHEDULE_WARNING.oxygen_lack_flag.id ===
                        item.oxygen_lack_flag
                      "
                    >
                      <div
                        :class="`warning-icon ${SCHEDULE_WARNING.oxygen_lack_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.oxygen_lack_flag.name }}
                      </div>
                    </template>
                  </td>
                  <td class="content">
                    {{ item.contents }}
                  </td>
                </tr>
              </table>
            </td>
            <td
              v-if="ENV_OBAYASHI"
              @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)"
            >
              {{ item.main_contractor_user_name }}
            </td>
            <td
              v-if="ENV_OBAYASHI"
              @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)"
              style="min-width: 70px"
            >
              {{ getDayWorkerType(item.dayworker_type) }}
            </td>
            <td @click="$emit('openItemForm', item)">
              <div class="d-flex" :class="{ 'flex-column': ENV_OBAYASHI }">
                <div>
                  <v-chip
                    v-if="item.early_over_type.earlytime_hour > 0"
                    class="ma-2"
                    small
                    :color="`${SCHEDULE_ATTENDANCE.early_flag.class}`"
                    dark
                    >{{
                      SCHEDULE_ATTENDANCE.early_flag.name +
                      "（" +
                      item.early_over_type.earlytime_hour +
                      " h）"
                    }}
                  </v-chip>
                </div>
                <div>
                  <v-chip
                    v-if="item.early_over_type.overtime_hour > 0"
                    class="ma-2"
                    small
                    :color="`${SCHEDULE_ATTENDANCE.over_flag.class}`"
                    dark
                    >{{
                      SCHEDULE_ATTENDANCE.over_flag.name +
                      "（" +
                      item.early_over_type.overtime_hour +
                      " h）"
                    }}
                  </v-chip>
                </div>
              </div>
            </td>
            <td @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)">
              <span>{{ item.dayworker_plan }}</span>
            </td>
            <td @click="openRecord(item, true, IS_PARENT_EDIT_TABLE)">
              <span
                v-if="
                  item.dayworker_result_update_user_id != null ||
                  item.dayworker_result_create_user_id != null ||
                  !ENV_OBAYASHI
                "
                >{{ item.dayworker_result }}</span
              >
              <span
                v-if="
                  item.dayworker_result_update_user_id == null &&
                  item.dayworker_result_create_user_id == null &&
                  ENV_OBAYASHI
                "
                >--</span
              >
              <span v-else-if="item.dayworker_result == null && ENV_OBAYASHI"
                >--</span
              >
              <br />
              <span v-if="item.total_dayworker_result != null"
                >({{ item.total_dayworker_result }})</span
              >
            </td>
          </tr>
          <!-- child table template -->
          <template v-for="itemChild in item.children">
            <tr
              :class="
                itemChild.state_handling_flag == 1 ? 'grey lighten-1' : ''
              "
              v-show="isShowChildren[item.work_result_id]"
              class="child-table"
              :key="itemChild.work_result_id"
            >
              <td class="start">
                <v-row v-if="!ENV_OBAYASHI">
                  <v-col cols="12" sm="3"> </v-col>
                  <v-col cols="12" sm="3"> </v-col>
                  <v-col cols="12" sm="3">
                    <v-simple-checkbox
                      v-if="!isTableTypeAll"
                      :value="isSelected(itemChild.work_result_id)"
                      @input="updateCheckbox(itemChild, $event, null, item)"
                      :disabled="isDisableCheckBox"
                    ></v-simple-checkbox>
                  </v-col>
                  <v-col cols="12" sm="3"> </v-col>
                </v-row>
                <v-row v-else justify="end">
                  <v-col cols="12" sm="3">
                    <v-simple-checkbox
                      v-if="!isTableTypeAll"
                      :value="isSelected(itemChild.work_result_id)"
                      @input="updateCheckbox(itemChild, $event, null, item)"
                      :disabled="isDisableCheckBox"
                    ></v-simple-checkbox>
                  </v-col>
                </v-row>
              </td>
              <td class="col-status">
                <div
                  v-for="(status, index) in approveStatus"
                  :key="`status_${status}_${index}_2`"
                >
                  <v-chip
                    v-if="status.id === itemChild.work_approval_flag"
                    @click="$emit('openPopupRatify', itemChild)"
                    class="ma-2"
                    small
                    :color="`${status.class}`"
                    dark
                    >{{ status.name }}
                    {{ getTotalApproval(itemChild.total_approval) }}</v-chip
                  >
                </div>
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                {{ itemChild.company_name }}
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                {{ convertDate(itemChild.work_date) }}
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                {{ itemChild.job_name }}
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                {{ convertString(itemChild.location_name, 16) }}
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                <table>
                  <tr>
                    <td>
                      <div
                        v-if="
                          SCHEDULE_WARNING.hot_work_flag.id ===
                          itemChild.hot_work_flag
                        "
                        :class="`warning-icon ${SCHEDULE_WARNING.hot_work_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.hot_work_flag.name }}
                      </div>
                      <div
                        v-if="
                          SCHEDULE_WARNING.oxygen_lack_flag.id ===
                          itemChild.oxygen_lack_flag
                        "
                        :class="`warning-icon ${SCHEDULE_WARNING.oxygen_lack_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.oxygen_lack_flag.name }}
                      </div>
                    </td>
                    <td class="content">
                      {{ itemChild.contents }}
                    </td>
                  </tr>
                </table>
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
                v-if="ENV_OBAYASHI"
              >
                {{ itemChild.main_contractor_user_name }}
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
                v-if="ENV_OBAYASHI"
              >
                {{ getDayWorkerType(itemChild.dayworker_type) }}
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                <div class="d-flex" :class="{ 'flex-column': ENV_OBAYASHI }">
                  <div>
                    <v-chip
                      v-if="itemChild.early_over_type.earlytime_hour > 0"
                      class="ma-2"
                      small
                      :color="`${SCHEDULE_ATTENDANCE.early_flag.class}`"
                      dark
                      >{{
                        SCHEDULE_ATTENDANCE.early_flag.name +
                        "（" +
                        itemChild.early_over_type.earlytime_hour +
                        " h）"
                      }}
                    </v-chip>
                  </div>
                  <div>
                    <v-chip
                      v-if="itemChild.early_over_type.overtime_hour > 0"
                      class="ma-2"
                      small
                      :color="`${SCHEDULE_ATTENDANCE.over_flag.class}`"
                      dark
                      >{{
                        SCHEDULE_ATTENDANCE.over_flag.name +
                        "（" +
                        itemChild.early_over_type.overtime_hour +
                        " h）"
                      }}
                    </v-chip>
                  </div>
                </div>
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                <span>{{ itemChild.dayworker_plan }}</span>
              </td>
              <td
                @click="openRecord(itemChild, true, IS_CHILD_EDIT_TABLE, item)"
              >
                <span
                  v-if="
                    itemChild.dayworker_result_update_user_id != null ||
                    itemChild.dayworker_result_create_user_id != null ||
                    !ENV_OBAYASHI
                  "
                  >{{ itemChild.dayworker_result }}</span
                >
                <span
                  v-if="
                    itemChild.dayworker_result_update_user_id == null &&
                    itemChild.dayworker_result_create_user_id == null &&
                    ENV_OBAYASHI
                  "
                  >--</span
                >
                <span
                  v-else-if="itemChild.dayworker_result == null && ENV_OBAYASHI"
                  >--</span
                >
              </td>
            </tr>
          </template>
          <tr
            v-show="
              childTableDisplayMoreNumber(
                item.total_children_item,
                item.children.length
              ) > 0 && isShowChildren[item.work_result_id]
            "
          >
            <td colspan="8">
              <div class="d-flex justify-center">
                <v-btn
                  x-small
                  depressed
                  :disabled="isDisable"
                  class="mr-4 display-more"
                  color="#E5E5E5"
                  v-if="
                    childTableDisplayMoreNumber(
                      item.total_children_item,
                      item.children.length
                    ) > 0
                  "
                  @click="getChildren(item)"
                  >さらに表示する
                  <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                  />
                </v-btn>
                <!-- <v-btn
                  class="add-new-child"
                  depressed
                  x-small
                  v-if="!isTableTypeAll"
                  @click="$emit('addNewChild', item)"
                  color="primary"
                  >作業を新規追加</v-btn
                > -->
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr class="disable-hover">
            <td colspan="12">
              <infinite-loading @infinite="infiniteHandler" spinner="waveDots">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </td>
          </tr>
        </tbody>
      </template>
      <div v-else-if="flagNotData" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import _ from "lodash";
import { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import { IS_CHILD_EDIT_TABLE, IS_PARENT_EDIT_TABLE } from "@/constants/COMMON";
import { CONTRACT_CLASSIFICATION } from "@/constants/SCHEDULE_WORKS";
import InfiniteLoading from "vue-infinite-loading";
import { Store } from "@/store/Store.js";

export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      isDisable: false,
      time_out: null,
      IS_CHILD_EDIT_TABLE,
      IS_PARENT_EDIT_TABLE,
      CONTRACT_CLASSIFICATION,

      page: 1,
      list: [],
      total_page: 1,
      isShowChildren: {},
      isOpenAllChildren: {},
      IDRecordUpdate: [],
      IDDelete: [],
    };
  },
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    isTableTypeAll: Boolean,
    items: Array,
    isSelected: Function,
    isSelectedParent: Function,
    updateCheckbox: Function,
    // toggleShowChildren: Function,
    childTableDisplayMoreNumber: Function,
    // isShowChildren: Object,
    approveStatus: Array,
    SCHEDULE_ATTENDANCE: Object,
    SCHEDULE_WARNING: Object,
    NO_DATA_MESSAGE: String,
    flagNotData: Boolean,
    updateAllCheckbox: Function,
    isSelectedAllParent: Function,
    isDisableCheckBox: Boolean,
    paramsGetList: Object,
    itemSelectedScroll: Array,
    listDeleteScroll: Array,
  },
  computed: {
    ENV_OBAYASHI() {
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    params() {
      const result = {
        ...this.paramsGetList,
      };
      if (result.searchParams) {
        result.searchParams.page_number = this.page;
        result.searchParams.record_size = 500;
      }
      return {
        ...result,
      };
    },

    checkLoadData() {
      return {
        getFetch: Store.getters["ScheduleWorks/getFetch"],
        itemSelectedScroll: [...this.itemSelectedScroll],
        listDeleteScroll: [...this.listDeleteScroll],
      };
    },
  },
  mounted() {
    this.$watch(
      () => this.checkLoadData,
      (newValue) => {
        if (newValue.itemSelectedScroll && newValue.itemSelectedScroll.length) {
          this.IDRecordUpdate = _.cloneDeep(newValue.itemSelectedScroll);
        }

        if (newValue.listDeleteScroll && newValue.listDeleteScroll.length) {
          this.IDDelete = _.cloneDeep(newValue.listDeleteScroll);
        }

        if (newValue.getFetch == "view") {
          this.infiniteHandler();
        }
        if (newValue.getFetch == "update") {
          this.loadRecordLastUpdate();
        }

        if (newValue.getFetch == "onSearch") {
          this.page = 1;
          this.list = [];
          this.infiniteHandler();
        }

        if (newValue.getFetch == "updateApprove") {
          this.params.searchParams.field_tree_id = null;
          this.params.searchParams.dayworker_type = [];
          this.params.searchParams.work_approval_flag = [];
          this.params.searchParams.group_id = null;
          this.params.searchParams.field_tree_id_lv1 = null;
          this.params.searchParams.field_item_tree_id = null;
          this.params.searchParams.work_user_plans_user_id = null;
          this.params.searchParams.field_tree_user_id = null;
          delete this.params.searchParams.self_approve_flg;
          delete this.params.searchParams.other_level_1_only_flg;
          this.loadRecordLastUpdate();
        }

        if (newValue.getFetch == "remove" && newValue.itemSelectedScroll) {
          // Delete record
          if (this.IDDelete && this.IDDelete.length) {
            this.deleteRecord(this.IDDelete);
          }

          // Update record
          this.IDRecordUpdate = newValue.itemSelectedScroll.map((item) => {
            return item.delete ? item.update : item;
          });
          const listReplace = [];
          newValue.itemSelectedScroll.forEach((item) => {
            if (item.delete) listReplace.push(item);
          });

          listReplace.forEach((record) => {
            const indexCheckList = this.list.findIndex((item) => {
              return String(item.work_result_id) == String(record.delete);
            });

            if (indexCheckList != -1) {
              this.list[indexCheckList].work_result_id = record.update;
            }
          });
          this.loadRecordLastUpdate();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.isShowChildren,
      (newValue, oldValue) => {
        if (newValue == oldValue) this.$forceUpdate();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.list,
      (value) => {
        this.$emit("updateItems", value);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    getDayWorkerType(type) {
      return type
        ? this.CONTRACT_CLASSIFICATION[type].name
        : this.CONTRACT_CLASSIFICATION[0].name;
    },
    async loadRecordLastUpdate() {
      if (
        this.params.searchParams &&
        this.IDRecordUpdate &&
        this.IDRecordUpdate.length
      ) {
        const paramsUpdate = _.cloneDeep(this.params);
        paramsUpdate.searchParams.page_number = 1;
        paramsUpdate.searchParams.ids = this.IDRecordUpdate;

        const res = await Store.dispatch(
          `ScheduleRecords/getListWorks`,
          paramsUpdate
        );
        if (res.data.status_code === 200) {
          this.updateRecord(res.data.contents.entries);

          this.$forceUpdate();
        }
      }
      this.time_out = setTimeout(() => {
        Store.commit("ScheduleWorks/SET_FETCH", null);
      }, 200);
    },

    async infiniteHandler($state) {
      if (this.page > this.total_page) {
        if ($state) $state.complete();
        return;
      }
      if (this.params.searchParams) {
        const res = await Store.dispatch(
          `ScheduleRecords/getListWorks`,
          this.params
        );
        if (res.data.status_code === 200) {
          this.page += 1;
          this.total_page = res.data.contents.pagination.total
            ? res.data.contents.pagination.total
            : 1;
          this.initIsShowChildren(res.data.contents.entries);

          this.list.push(...res.data.contents.entries);
          if ($state) $state.loaded();
        } else {
          if ($state) $state.complete();
        }
        Store.commit("ScheduleWorks/SET_FETCH", null);
      }
    },
    getChildren(item) {
      this.IDRecordUpdate = [item.work_result_id];

      this.isOpenAllChildren[item.work_result_id] = true;

      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.getChildItems(
        item.work_group_result_id,
        Math.floor((item.children.length - 3) / 5) + 2,
        item.work_result_id
      );
      this.updateCheckbox(item, false, true);
    },

    /**
     * 親ステートの子テーブルを更新
     */
    async getChildItems(fieldTreeGroupId, pageNumber, idRecord) {
      const result = _.cloneDeep(this.paramsGetList);
      result.searchParams["work_group_result_id"] = fieldTreeGroupId;
      result.searchParams["page_number"] = pageNumber;
      this.deleteParams(result.searchParams);
      if (this.ENV_OBAYASHI && !result.searchParams["self_approve_flg"]) {
        delete result.searchParams["self_approve_flg"];
      }

      const res = await Store.dispatch(
        `ScheduleRecords/loadChildrenScroll`,
        result
      );

      if (res.data.status_code === 200) {
        const index = this.list.findIndex((ele) => {
          return idRecord == ele.work_result_id;
        });

        if (index !== -1) {
          this.list[index].children = [
            ...this.list[index].children,
            ...res.data.contents.entries,
          ];
        }

        this.$forceUpdate();
      }
    },

    deleteParams(searchParams) {
      delete searchParams["date"];
      delete searchParams["end_date"];
      delete searchParams["table_type"];
      delete searchParams["page_count_all"];
      delete searchParams["current_page_all"];
      delete searchParams["total_page_all"];
      delete searchParams["total_item_all"];
      delete searchParams["page_count_group"];
      delete searchParams["current_page_group"];
      delete searchParams["total_page_group"];
      delete searchParams["total_item_group"];
      delete searchParams["all_record_schedule_all"];
      delete searchParams["all_record_schedule_group"];
      delete searchParams["schedule_flag"];
    },

    convertString(text, maxlength) {
      let result = "";
      if (text) {
        result = text;
        if (text.length > maxlength) {
          result = text.substring(0, maxlength) + " •••";
        }
      }
      return result;
    },

    getTotalApproval(total) {
      if (!this.ENV_OBAYASHI) return "";
      return `(${total || ""})`;
    },
    convertDate(date) {
      return date ? date.slice(5, 10) : "";
    },

    openRecord(item, isChild, IS_PARENT_EDIT_TABLE, itemFather) {
      this.IDRecordUpdate = itemFather
        ? [itemFather.work_result_id]
        : [item.work_result_id];
      this.$emit("openItemForm", item, isChild, IS_PARENT_EDIT_TABLE);
    },

    // parent table ごとの child tableの表示ステートをinit
    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.work_result_id] = true;
      });
      this.isShowChildren = {
        ...this.isShowChildren,
        ..._isShowChildren,
      };
    },
    // child tableの表示切り替え
    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },

    updateRecord(data) {
      data?.forEach((recordUpdate) => {
        const indexCheckList = this.list.findIndex((item) => {
          return item.work_result_id === recordUpdate.work_result_id;
        });

        if (indexCheckList != -1) {
          this.list.splice(indexCheckList, 1, recordUpdate);
        }
        this.isShowChildren[recordUpdate.work_result_id] = true;

        if (
          this.isOpenAllChildren[recordUpdate.work_result_id] &&
          this.childTableDisplayMoreNumber(
            recordUpdate.total_children_item,
            recordUpdate.children.length
          ) > 0
        ) {
          this.getChildren(recordUpdate);
        }
      });

      this.IDRecordUpdate = [];
    },

    async deleteRecord(data) {
      if (this.params.searchParams &&
        (!this.IDRecordUpdate ||
        !this.IDRecordUpdate.length)) {
          const paramsUpdate = _.cloneDeep(this.params);
          paramsUpdate.searchParams.page_number = 1;
          paramsUpdate.searchParams.ids = [this.list[0].work_result_id];
          await Store.dispatch(
              `ScheduleRecords/getListWorks`,
              paramsUpdate
          );
          this.$forceUpdate();
      }
      
      data?.forEach((IDDelete) => {
        const indexCheckList = this.list.findIndex((item) => {
          return String(item.work_result_id) == String(IDDelete);
        });

        if (indexCheckList != -1) {
          this.list.splice(indexCheckList, 1);
        }
      });

      this.IDDelete = [];
      this.$forceUpdate();
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

.disable-hover:hover {
  background-color: transparent !important;
}

td.start div.row {
  width: 120px !important;
}
td.col-status {
  width: 110px !important;
}
.tableRow {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: $color_table_border;
    z-index: 1;
  }
}
.borderRow {
  &:before {
    left: 0;
    top: 0;
    background-color: $color_table_border_customize;
  }
}

.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.add-new-child,
button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}
div.circle-red {
  background-color: $color_warning_dark_red;
}
div.circle-blue {
  background-color: $color_warning_blue;
}
div.warning-icon {
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin: 3px;
}
.content {
  word-break: break-all;
}
.select-all {
  position: absolute;
  margin-top: -35px;
  margin-left: 45px;
  z-index: 3;
}
</style>
