<template>
  <div>
    <Loading v-if="loadingCopyRecord" />
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader v-if="!$sp" />
        <!-- SP用ヘッダー -->
        <SpHeader
          v-if="$sp"
          :PAGE_TITLE="PAGE_TITLE"
          :constructionId="searchParams.field_construction_id"
          :isTableTypeAll="isTableTypeAll"
          @onSearch="onSearch"
        />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="judgeHiddenFooter">
          <template #tableHeader="{ updateHeader }">
            <div>
              <ScheduleHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiSelectStatus="disableApproveBtn"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :isDirector="isDirector"
                :isTableTypeAll="isTableTypeAll"
                :isOperational="true"
                :isCustomCalendar="true"
                :isObayashiAndContractorsMain="isObayashiAndContractorsMain"
                :disableBtnCreateSearchHistory="disableBtn"
                :listSearchHistory="listSearchHistory"
                :isCopyRecord="ENV_OBAYASHI"
                @openRemoveDialog="openRemoveDialog()"
                @onReject="openConfirmDialog(!APPROVE_FLAG)"
                @onApprove="openConfirmDialog(APPROVE_FLAG)"
                @onCopyRecord="openRangeDate"
                @openItemForm="openNewItemForm(IS_PARENT_EDIT_TABLE)"
                @onInput="onChangeSearchParams"
                @onOpenChart="onOpenChart(true)"
                @openPopupCreateSearchHistory="openPopupCreateSearchHistory"
                @seachByItemHistory="seachByItemHistory"
                @deleteItemsSeachHistory="deleteItemsSeachHistory"
              >
                <template v-slot="{ openSearch }">
                  <!--
                    SPでは全画面表示で絞り込みを表示するので、
                    このコンポーネントでラップする
                   -->
                  <SpSearchWrapper
                    @onSearch="onSearch"
                    :openSearch="openSearch"
                    :style="ENV_OBAYASHI ? 'align-items: center !important;':''"
                  >
                    <template #form>
                      <SearchFormWrapper SPenable>
                        <!--
                          SPの日付選択
                          SPでは日付選択を、絞り込みメニューに含める
                        -->
                        <InputDatepicker
                          v-if="$sp"
                          name="date"
                          :editable="true"
                          :values="searchParams"
                          :flagNull="true"
                          @onInput="onChangeSearchParams"
                        />
                        <Label label="工事" :width="$sp ? 'auto' : '200px'">
                          <SearchSite
                            name="field_construction_id"
                            :siteId="CURRENT_SITE.field_id"
                            :values="searchParams"
                            @onInput="onChangeSearchParams"
                          />
                        </Label>
                        <Label v-if="ENV_OBAYASHI" label="作業グループ" :width="$sp ? 'auto' : '200px'">
                          <Select
                            name="group_id"
                            :editable="true"
                            :values="searchParams"
                            :items="GROUP_ARRAY"
                            item_text="group_name"
                            @onInput="onChangeSearchParams"
                          />
                        </Label>
                        <Label v-if="!ENV_OBAYASHI" label="協力会社" :width="$sp ? 'auto' : '200px'">
                          <SearchCompanyPartner
                            name="field_tree_id"
                            :groupId="searchParams.group_id"
                            :fieldId="searchParams.field_construction_id"
                            :formValues="searchParams"
                            @onInput="onChangeSearchParams"
                          />
                        </Label>
                        <Label v-if="ENV_OBAYASHI" label="協力会社" editable :width="$sp ? 'auto' : '200px'">
                          <SelectMulti
                            ref="selectFieldTreeId"
                            class="select-position-multi"
                            name="field_tree_id_lv1"
                            :values="searchParams"
                            :items="PARTNER_LV1_SEARCH_LIST"
                            item_text="company_name"
                            item_value="field_tree_id"
                            :editable="true"
                            validation_label="協力会社"
                            @onInput="onChangeSearchParams"
                            :isReset="isReset"
                          />
                        </Label>
                        <Label label="契約">
                          <TabSelect
                            name="dayworker_type"
                            :items="SCHEDULE_CONTRACT"
                            :editable="true"
                            :values="searchParams"
                            @onInput="onChangeSearchParams"
                          />
                        </Label>
                        <Label label="ステータス">
                          <TabSelect
                            name="work_approval_flag"
                            :items="SCHDULE_APPROVE"
                            :editable="true"
                            :values="searchParams"
                            @onInput="onChangeSearchParams"
                          />
                        </Label>
                        <SwitchInput
                          v-if="ENV_OBAYASHI"
                          class="mt-6 ml-2 mr-3"
                          name="self_approve_flg"
                          :label="`自分が未承認のみ表示`"
                          :values="searchParams"
                          :editable="true"
                          @onInput="onChangeSearchParams"
                        />
                        <Label
                          label="作業場所"
                          :editable="true"
                          :width="$sp ? 'auto' : '200px'"
                        >
                          <SearchLocation
                            name="field_item_tree_id"
                            :siteId="CURRENT_SITE.field_id"
                            :values="searchParams"
                            :construction_id="searchParams.field_construction_id"
                            @onInput="onChangeSearchParams"
                          />
                        </Label>
                        <Label v-if="ENV_OBAYASHI" label="元請担当者" :width="$sp ? 'auto' : '200px'">
                          <Select
                            name="field_tree_user_id"
                            :editable="true"
                            :values="searchParams"
                            :items="WORK_USER_ARRAY"
                            @onInput="onChangeSearchParams"
                          />
                        </Label>
                      </SearchFormWrapper>
                    </template>
                    <template #navi>
                      <!--
                      - LABELコンポーネントは廃止
                      - SpSearchWrapper内でレイアウトを調整
                      - v-btnはPC用
                    -->
                    <div class="d-flex flex-column align-center">
                      <a href="/" style="color: blue" @click="resetFormSearch">検索条件のリセット</a>
                      <v-btn
                        style="margin-top: 13px;"
                        depressed
                        color="primary"
                        :text="$sp"
                        :disabled="disableBtn"
                        @click="onSearch"
                      >
                      検索
                      </v-btn>
                    </div>
                  </template>
                  </SpSearchWrapper>
                </template>
              </ScheduleHeader>
              <!-- テーブルの切り替え -->
              <SelectTimeframe
                class="mt-5"
                name="work_hour_zone"
                :constructionId="searchParams.field_construction_id"
                :values="searchParams"
                :isTableTypeAll="isTableTypeAll"
                @onInput="onChangeSearchParams"
              />
            </div>
          </template>

          <!--
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <RecordsTablesAll
              v-show="isTableTypeAll"
              pageKey="worksAll"
              :tableLabels="TABLE_LABELS"
              :tableHeight="tableHeight"
              :items="items"
              :flagNotData="flagNotData"
              :itemsPerPage="searchParams.page_count_all"
              :isSelected="isSelected"
              :isDisableCheckBox="isDisableCheckBox"
              :isTableTypeAll="isTableTypeAll"
              :openItemForm="openItemForm"
              :getChildItems="getChildItems"
              :addNewChild="addNewChild"
              :key="`works_all_${reloadListAll}`"
              @update="updateSelectedItems"
            />
            <RecordsTablesGroupOba
              v-show="!isTableTypeAll"
              ref="RecordsTablesGroupOba"
              pageKey="worksGroup"
              :tableLabels="TABLE_LABELS"
              :tableHeight="tableHeight"
              :items="itemsGroup"
              :flagNotData="flagNotData"
              :isDisableCheckBox="isDisableCheckBox"
              :itemsPerPage="searchParams.page_count_group"
              :isSelected="isSelected"
              :checkSelectedParents="checkSelectedParents"
              :isTableTypeAll="isTableTypeAll"
              :openItemForm="openItemForm"
              :getChildItems="getChildItems"
              :addNewChild="addNewChild"
              :key="`works_group_${reloadListGroup}`"
              @update="updateSelectedItems"
              @openPopupRatify="openPopupRatify"
              @updateItems="updateItems"
              :uncheckAll="uncheckAll"
              :paramsGetList="paramsGetList"
              :itemSelectedScroll="itemSelectedScroll"
              :listDeleteScroll="listDeleteScroll"
            />
          </template>

          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <PaginationAll
              v-if=" isTableTypeAll"
              :current="searchParams.current_page_all"
              :total="searchParams.total_page_all"
              @pageUpdate="pageUpdate"
            />
            <!-- <PaginationGroup
              v-else
              :current="searchParams.current_page_group"
              :total="searchParams.total_page_group"
              @pageUpdate="pageUpdate"
            /> -->
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <RecordWorkFormObayashi
        v-if="ENV_OBAYASHI"
        :isNewItem="isNewItem"
        :workGroupResults="workGroupResults"
        :isParent="isParent"
        :scheduleFlag="SCHEDULE_FLAG.RECORD"
        :workDate="searchParams.date"
        :isWorkPlan="isWorkPlan"
        :isTablesGroup="!isTableTypeAll"
        :isMyWork="isMyWork"
        @submit="submitForm"
        @cancel="closeItemForm"
        :isParentEditable="isParentEditable"
      />
      <RecordWorkForm
        v-else
        :isNewItem="isNewItem"
        :workGroupResults="workGroupResults"
        :isParent="isParent"
        :scheduleFlag="SCHEDULE_FLAG.RECORD"
        :workDate="searchParams.date"
        :isWorkPlan="isWorkPlan"
        :isTablesGroup="!isTableTypeAll"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
    </Popup>
    <!-- 全人工表示 -->
    <Popup width="440" :dialog="popups.isShowChart">
      <ScheduleChart
        scheduleName="ScheduleRecords"
        @close="onOpenChart(false)"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDialog
        :dialogText="dialogApproveText"
        @close="closeApproveDialog()"
        @yes="handleConfirm()"
        :warning="!dialogApproveText.approveFlg"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        :dialogText="dialogRemoveText"
        @close="closeRemoveDialog()"
        @yes="onRemove()"
        warning
      />
    </Popup>
    <Popup width="300px" :dialog="popups.isShowPopupRangeDate">
      <PopupRangeDate
        @close="closeRangeDate()"
        @yes="handleConfirmDuplicate"
      />
    </Popup>
    <Popup width="960px" :dialog="popups.isShowPopupRatify">
      <PopupRatify @close="closePopupRatify"
        @updateRafity="updateRafity"
        :values="listStatusApproval"
        :state_handling_flag="state_handling_flag"
        :workResultApproval="workResultApproval"
      />
    </Popup>
    <Popup width="480px" :dialog="isShowConfirmDeleteItemSearch">
      <ConfirmCloseDialog
        title="削除確認"
        text1="選択したデータを削除します。<br/>
        本当に削除してもよろしいですか?"
        text2="この処理を元に戻すことはできません。"
        textBtnClick="削除"
        @close="(isShowConfirmDeleteItemSearch = false)"
        @yes="clickConfirmDeleteItemSearch"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="isShowCreateSearchHistory" :key="isShowCreateSearchHistory">
      <FormCreateSearchHistory
        @close="isShowCreateSearchHistory=false"
        @createSearchHistory="createSearchHistory"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import PaginationAll from "@/components/masterTable/elements/Pagination";
import PaginationGroup from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "@/components/dialog/ConfirmRemoveDialog"; //削除確認ダイアログ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { TABLES_PER_PAGE } from "@/constants/COMMON"; //絞り込みフォームで使用

/**
 * コンポーネント、関数
 */
import RecordWorkForm from "@/components/forms/schedule/records/index"; //ユーザー登録編集フォーム
import RecordWorkFormObayashi from "@/components/forms/schedule/records/obayashi/index";
import { fmtSlashDate } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import { getRollLabelById } from "@/utils/users"; //ロール情報の変換で使用
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import ConfirmDialog from "../works/components/ConfirmDialog.vue";
import PopupRatify from "../works/components/PopupRatify.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import SelectMulti from "./components/SelectMulti.vue";

/**
 * 固有のコンポーネント
 */
import ScheduleHeader from "../components/ScheduleHeader.vue";
import TableTab from "../components/TableTab.vue"; //テーブルの切り替え
import SearchSite from "../components/SearchSite.vue"; //絞り込みフォームで使用
import SearchCompanyPartner from "../components/SearchCompanyPartner.vue"; //絞り込みフォームで使用
import SearchCompanyPartnerLv1 from "../components/SearchCompanyPartnerLv1.vue"; //絞り込みフォームで使用
import SearchLocation from "../components/SearchLocations.vue"; //絞り込みフォームで使用
import SelectTimeframe from "../works/components/SelectTimeframe.vue"; //絞り込みフォームで使用
import RecordsTablesAll from "./components/RecordsTables.vue"; //絞り込みフォームで使用
import RecordsTablesGroupOba from "./components/RecordsTablesOba.vue"; //絞り込みフォームで使用

import { WORKER_INITAL_ITEM } from "@/constants/WORKER"; //絞り込みフォームで使用
import {
  SCHEDULE_RECORDS_TABLE_LABELS,
  SCHDULE_SORT_ITEMS,
  SCHEDULE_CONTRACT,
  SCHDULE_APPROVE,
  SCHEDULE_TABLE_TAB_ITEMS,
  DIALOG_TEXT_RECORDS,
  DIALOG_REMOVE_TEXT,
  SCHEDULE_FLAG,
} from "@/constants/SCHEDULE_WORKS";

import { format } from "date-fns";

import ScheduleChart from "../components/ScheduleChart/ScheduleChart.vue";
import Loading from "@/components/loading/Loading.vue";

/**
 * SP
 */
import SpHeader from "@/components/globalHeader/SpHeader.vue";
import SpSearchWrapper from "@/components/masterTable/elements/SpSearchWrapper.vue";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import { compareStartEndDate } from "@/utils/timeUtil.js";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import _ from "lodash";
import { IS_CHILD_EDIT_TABLE, IS_PARENT_EDIT_TABLE } from "@/constants/COMMON";
import ConfirmCloseDialog from "../components/ConfirmCloseDialog.vue";
import FormCreateSearchHistory from "@/components/forms/schedule/records/FormCreateSearchHistory";
import PopupRangeDate from "../components/PopupRangeDate.vue";

/**
 * API
 */
// import { scheduleChildren } from "@/api/modules/scheduleChildren";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "作業実績";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

//ストア
const STORE = "ScheduleRecords";

//テーブルヘッダーラベル
const TABLE_LABELS = SCHEDULE_RECORDS_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = SCHDULE_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = WORKER_INITAL_ITEM;

// 承認: 1, 承認解除: 0
const ACTION_APPROVE = 1;
const ACTION_REJECT = 0;

const APPROVE_FLAG = true;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      loadingCopyRecord: false,
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      PAGE_COUNT_OPTIONS,
      APPROVE_FLAG,
      SCHEDULE_FLAG,
      GROUP_ARRAY: [{ id: null, group_name: "" }],
      WORK_USER_ARRAY: [{ id: null, name: "" }],
      PARTNER_LV1_SEARCH_LIST: [],
      /**
       * (共通)
       * 一覧データ
       */
      items: [],
      itemsGroup: [],

      workGroupResults: {},

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: { ...INITIAL_ITEM },

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,
      isDirector: false,
      isWorkPlan: false,
      isMyWork: true,

      // 検索パラメータ
      searchParams: {
        date: null, //初期値は今日
        end_date : null,
        schedule_flag: SCHEDULE_FLAG.RECORD,
        field_construction_id: null, //会社id
        field_tree_id: null, //会社id
        field_item_tree_id: null, //作業場所id
        work_hour_zone: null, //時間帯id
        table_type: SCHEDULE_TABLE_TAB_ITEMS.WORK_GROUP.id, // 1:全体,2:作業グループ
        dayworker_type: null, //[SCHEDULE_CONTRACT.UNDERTAKE.id], //契約
        work_approval_flag: null, //[SCHDULE_APPROVE.APPROVED.id], //承認
        page_count_all: PAGE_COUNT,
        current_page_all: 1,
        total_page_all: 1,
        page_count_group: PAGE_COUNT,
        current_page_group: 1,
        total_page_group: 1,
        all_record_schedule_all: 0,
        all_record_schedule_group: 0,
        other_level_1_only_flg: null,
        group_id: null
      },

      isInitRole: false,

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // チャート
        isShowChart: false,
        isShowConfirmDialog: false,
        isShowRemoveDialog: false,
        isShowPopupRatify: false,
        isShowPopupRangeDate: false
      },

      dialogApproveText: {
        title: "",
        text1: "",
        text2: "",
        btnSubmitText: "",
        approveFlg: false,
        isShowCloseBtn: true,
      },
      dialogRemoveText: {
        title: "",
        text1: "",
        text2: "",
        text3: null,
        text4: null,
        btnSubmitText: "",
      },
      reloadListAll: false,
      reloadListGroup: false,
      isParent: false,
      flagNotData: false,
      ENV_CLIENT,
      listStatusApproval: [],
      work_plan_id_choose: null,
      state_handling_flag: null,
      isDisableCheckBox: true,
      uncheckAll: [],
      isParentEditable: false,
      IS_CHILD_EDIT_TABLE,
      IS_PARENT_EDIT_TABLE,
      workResultApproval: {
        job_name: '',
        contents: '',
        work_date: '',
        dayworker_result: '',
      },
      isShowConfirmDeleteItemSearch: false,
      isShowCreateSearchHistory : false,
      listSearchHistory: [],
      idItemSearchHistory: null,
      isReset: false,
      paramsGetList: null,
      itemSelectedScroll: [],
      listDeleteScroll: []
    };
  },

  components: {
    PopupRangeDate,
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    PaginationAll,
    PaginationGroup,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    RecordWorkForm,
    InputText,
    InputDatepicker,
    Select,
    Label,
    ConfirmDialog,
    PopupRatify,

    //固有のコンポーネント
    ScheduleHeader,
    TabSelect,
    SwitchInput,
    TableTab,
    SearchSite,
    SearchCompanyPartner,
    SearchCompanyPartnerLv1,
    SelectMulti,
    SearchLocation,
    SelectTimeframe,
    RecordsTablesAll,
    RecordsTablesGroupOba,
    ScheduleChart,

    //SP
    SpHeader,
    SpSearchWrapper,
    RecordWorkFormObayashi,
    ConfirmCloseDialog,
    FormCreateSearchHistory,
    Loading
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
      await Store.dispatch("GlobalHeader/setSiteMenu", {
        menuId: HEADER_MENU_ITEMS_SITE.WORK_RECORD.id,
      });
    }else {
      await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
      });
    }
    const searchParams = { ...this.searchParams };
    const _date = new Date();
    _date.setDate(_date.getDate());
    searchParams.date = format(_date, "yyyy-MM-dd");
    searchParams.end_date = format(_date, "yyyy-MM-dd");
    if (this.ENV_OBAYASHI) {
      searchParams["group_id"] = null;
      searchParams["field_tree_user_id"] = null;
    }
    this.searchParams = searchParams;

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getDataAll`],
        Store.getters[`${STORE}/getDataGroup`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        this.items = _.cloneDeep(data[0]);
        this.itemsGroup = _.cloneDeep(data[1]);

        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        if (this.isTabAll()) {
          searchParams.total_page_all = data[2].total;
          searchParams.current_page_all = parseInt(data[2].current);
          searchParams.total_item_all = data[2].total_item;
          searchParams.all_record_schedule_all = data[2].all_record;
        } else {
          searchParams.total_page_group = data[2].total;
          searchParams.current_page_group = parseInt(data[2].current);
          searchParams.total_item_group = data[2].total_item;
          searchParams.all_record_schedule_group = data[2].all_record;
        }
        this.flagNotData = data[2].total_item == 0;
        this.searchParams = searchParams;
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["ScheduleWorks/getFetch"],
      (newValue) => {
        if(newValue) {
          this.loadingCopyRecord = true
        }  else {
          this.loadingCopyRecord = false
          this.itemSelectedScroll = []
          this.listDeleteScroll = []
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Employees/getIsDirector"],
      (data) => {
        this.isDirector = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        this.GROUP_ARRAY = [{ id: null, group_name: "" }, ...data];
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListDirectorsObayashi"],
      (data) => {
        let _data = [...data];
        _data = _data.map(e => {
          e.name = e.user_name_sei + " " + e.user_name_mei;
          return e;
        });
        this.WORK_USER_ARRAY = [{ id: null, name: "" }, ..._data];
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["ScheduleRecords/getListStatusApproval"],
      (data) => {
        this.listStatusApproval = [ ...data];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [this.searchParams.field_construction_id, this.searchParams.group_id, this.searchParams.other_level_1_only_flg],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.getPartnerLv1();
        }
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => this.searchParams.field_construction_id,
      (newValue, oldValue) => {
        if(newValue && newValue != oldValue){
          this.getUserRolePrime()
        }
      },
      { immediate: true, deep: true }
    )
    this.$watch(
      () => Store.getters["ScheduleRecords/getListSearchHistoryWorkResult"],
      (newValue) => {
        this.listSearchHistory = _.cloneDeep(newValue)
        this.listSearchHistory.forEach(item => {
          item.contents = JSON.parse(item.contents)
        })
      },
      { deep: true }
    )
    this.$watch(
      () => this.CURRENT_SITE.field_id,
      (newValue, oldValue) => {
        if(newValue && newValue != oldValue) {
          this.getListSearchHistoryWorkResult()
        }
      },
      {
        immediate: true,
        deep: true
      }
    )
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {

    /**
     * 承認・承認解除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableApproveBtn() {
      return this.selectedItems.length === 0;
    },

    disableBtn(){
      return !this.checkStartEndDate();
    },

    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    /**
     * (スケジュール)
     * 絞り込み:契約項目
     */
    SCHEDULE_CONTRACT() {
      return Object.keys(SCHEDULE_CONTRACT).map((key) => {
        return SCHEDULE_CONTRACT[key];
      });
    },

    /**
     *  (スケジュール)
     * 絞り込み:承認/未承認
     */
    SCHDULE_APPROVE() {
      return Object.keys(SCHDULE_APPROVE).map((key) => {
        return SCHDULE_APPROVE[key];
      });
    },

    /**
     *  (スケジュール)
     * テーブルタブ
     */
    SCHEDULE_TABLE_TAB_ITEMS() {
      return Object.keys(SCHEDULE_TABLE_TAB_ITEMS).map((key) => {
        return SCHEDULE_TABLE_TAB_ITEMS[key];
      });
    },

    /**
     * テーブルが全体を選択
     */
    isTableTypeAll() {
      return this.searchParams.table_type === SCHEDULE_TABLE_TAB_ITEMS.ALL.id;
    },

    judgeHiddenFooter() {
      return (this.isTableTypeAll && this.searchParams.page_count_all >= this.searchParams.total_item_all)
          || (!this.isTableTypeAll && this.searchParams.page_count_group >= this.searchParams.total_item_group);
    },

    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },

    isObayashiAndContractorsMain() {
      return this.ENV_OBAYASHI && Store.state.Report.userRolePrime == 1
    },
  },

  methods: {
    async getUserRolePrime() {
      await Store.dispatch(`Report/getUserRolePrime`, {
        id: this.CURRENT_SITE.field_id,
        value: {
          field_construction_id: this.searchParams.field_construction_id
        }
      });
    },
    isTabAll() {
      return this.searchParams.table_type === SCHEDULE_TABLE_TAB_ITEMS.ALL.id;
    },

    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      this.uncheckAll = this.items;
      let searchParams = { ...this.searchParams };
      if (this.isTableTypeAll) {
        searchParams.current_page_all = n;
      } else {
        searchParams.current_page_group = n;
      }
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["current_page_all"] = 1;
      this.searchParams["current_page_group"] = 1;

      this.getItems('onSearch');
      this.selectedItems = [];
      this.reloadListAll = !this.reloadListAll;
      this.reloadListGroup = !this.reloadListGroup;
    },
    async getPartnerLv1() {
      let searchParams = { ...this.searchParams };
      if (!searchParams.field_construction_id && !searchParams.group_id) return;
      const params = {
        field_construction_id: searchParams.field_construction_id,
        field_tree_group_id: searchParams.group_id,
        other_level_1_only_flg: searchParams.other_level_1_only_flg
      };

        let results = []
        if (this.ENV_OBAYASHI) {
          results = await Store.dispatch("PortalChart/getAllListCompany", { params });
          this.PARTNER_LV1_SEARCH_LIST = results.data.contents?.entries;
        } else {
          results = await Store.dispatch("PortalChart/getListCompanyPartnerLevel1", { params });
          this.PARTNER_LV1_SEARCH_LIST = [...results.data.contents.entries];
        }
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name === "date" || name === "end_date") {
        searchParams[name] = format(new Date(value), "yyyy-MM-dd");
      } else if (name === 'field_tree_id_lv1') {
        searchParams['field_tree_id'] = value;
      } else {
        searchParams[name] = value;
      }
      if (name == "field_construction_id") {
        searchParams.field_item_tree_id = null;
        this.callApiCheckIsDirector(value);
        if (this.ENV_OBAYASHI) {
          searchParams.field_tree_user_id = null;
          searchParams.group_id = null;
          this.getDataGroupArray(value);
          this.getDataListFieldDirector(value,null);
        }
      }
      if (name == "group_id") {
        this.getDataListFieldDirector(searchParams.field_construction_id, value);
      }
      this.searchParams = searchParams;
      if (name == "date" || name == "end_date" || name == "work_hour_zone") {
        this.searchParams["current_page_all"] = 1;
        this.searchParams["current_page_group"] = 1;
        this.getItems();
        this.selectedItems = [];
        this.reloadListAll = !this.reloadListAll;
        this.reloadListGroup = !this.reloadListGroup;
      }
    },

    async getDataGroupArray(fieldConstructionId) {
      const params = {
        field_construction_id: fieldConstructionId,
        all_group_flag: 1
      };
      await Store.dispatch("PortalChart/getListGroups", params);
    },
    async getDataListFieldDirector(fieldConstructionId,groupId) {
      const params = {
        field_construction_id: fieldConstructionId,
        group_id: groupId,
        state_handling_flag: 0
      };
      if (groupId) {
        delete params.field_construction_id;
      } else {
        delete params.group_id;
      }
      await Store.dispatch("PortalChart/getFieldDirectorsObayashi", params);
    },
    checkStartEndDate(){
      let startDate = new Date(this.searchParams.date?.replaceAll("-", "/"));
      let endDate = new Date(this.searchParams.end_date?.replaceAll("-", "/"));
      return (format(new Date(this.searchParams.date), "yyyy/MM/dd") <= format(new Date(this.searchParams.end_date), "yyyy/MM/dd") && compareStartEndDate(startDate, endDate));
    },

    async onOpenChart(val) {
      if (val) {
        const apiParams = {
          start_work_date: this.searchParams.date,
          end_work_date : this.searchParams.end_date,
          field_construction_id: this.searchParams.field_construction_id,
          work_hour_zone: this.searchParams.work_hour_zone,
        };
        if (this.ENV_OBAYASHI) {
          apiParams["is_oba"] = 1;
        }
        await Store.dispatch(`${STORE}/getDayWorkerInfo`, apiParams);
      }
      const popups = { ...this.popups };
      popups.isShowChart = val;
      this.popups = popups;
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm(statusAddNew) {
      this.editedItem = { ...INITIAL_ITEM };
      if (!this.workGroupResults["field_tree_id_1"]) {
        this.workGroupResults["field_construction_id"] = this.searchParams.field_construction_id;
        this.workGroupResults["work_hour_zone"] = this.searchParams.work_hour_zone;
      }
      this.isNewItem = true;
      this.isParentEditable = statusAddNew;
      this.popups.isShowItemForm = true;
    },
    openItemForm(item, isWorkPlan, isParentEditable) {
      this.isParentEditable = isParentEditable;
      this.isWorkPlan = isWorkPlan;
      this.workGroupResults["id"] = item.work_result_id;
      this.isParent = item.children && item.children.length == 0 ? true : false;
      this.isMyWork = (item.is_my_work && item.is_my_work == 0) ? false : true;
      this.isNewItem = false;
      this.popups.isShowItemForm = true;
    },
    closeItemForm(isCompleteEdit) {
      this.popups.isShowItemForm = false;
      this.workGroupResults = {};
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM };
      });
      if(isCompleteEdit && this.isParent) {
      //  this.searchParams["current_page_all"] = 1;
      //  this.searchParams["current_page_group"] = 1;
      }
      // this.getItems();
    },

    deleteParams(searchParams) {
      delete searchParams["date"];
      delete searchParams["end_date"];
      delete searchParams["table_type"];
      delete searchParams["page_count_all"];
      delete searchParams["current_page_all"];
      delete searchParams["total_page_all"];
      delete searchParams["total_item_all"];
      delete searchParams["page_count_group"];
      delete searchParams["current_page_group"];
      delete searchParams["total_page_group"];
      delete searchParams["total_item_group"];
      delete searchParams["all_record_schedule_all"];
      delete searchParams["all_record_schedule_group"];
      delete searchParams["schedule_flag"];
    },

    /**
     * 親ステートの子テーブルを更新
     */
    async getChildItems(fieldTreeGroupId, pageNumber) {
      let searchParams = { ...this.searchParams };
      searchParams["work_group_result_id"] = fieldTreeGroupId;
      searchParams["start_work_date"] = searchParams["date"];
      searchParams["end_work_date"] = searchParams["end_date"];
      searchParams["page_number"] = pageNumber;
      this.deleteParams(searchParams);
      let isTabAll = this.isTabAll();
      if (this.ENV_OBAYASHI) {
        searchParams["is_oba"] = 1;
      }
      const params = {
        searchParams: searchParams,
        isTabAll: isTabAll,
      };
      if (this.ENV_OBAYASHI && !searchParams["self_approve_flg"]) {
        delete searchParams["self_approve_flg"];
      }
      await Store.dispatch(`${STORE}/loadChildren`, params);
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems(strSetFetch) {
      if (this.checkStartEndDate()) {
        let searchParams = { ...this.searchParams };
        searchParams["start_work_date"] = searchParams["date"];
        searchParams["tab"] = SCHEDULE_TABLE_TAB_ITEMS.ALL.id;
        searchParams["end_work_date"] = searchParams["end_date"];
        this.isDisableCheckBox = true

        // field_tree_idが配列である場合、カンマ区切りの文字列に変換
        if (Array.isArray(searchParams.field_tree_id)) {
          searchParams.field_tree_id = searchParams.field_tree_id.join(',');
        }

        if (this.ENV_OBAYASHI && !searchParams["self_approve_flg"]) {
          delete searchParams["self_approve_flg"];
        }
        if (this.isTabAll()) {
          searchParams["page_number"] = searchParams["current_page_all"];
        } else {
          searchParams["page_number"] = searchParams["current_page_group"];
        }
        if (
          searchParams.start_work_date && searchParams.end_work_date &&
          searchParams.schedule_flag === SCHEDULE_FLAG.RECORD &&
          searchParams.field_construction_id
        ) {
          let isTabAll = this.isTabAll();
          if (this.ENV_OBAYASHI) {
            searchParams["is_oba"] = 1;
          }
          const params = {
            searchParams: searchParams,
            isTabAll: isTabAll
          };

          this.paramsGetList = params
          this.deleteParams(searchParams);

          if (strSetFetch) {
            Store.commit(`ScheduleWorks/SET_FETCH`, strSetFetch)
          } else Store.commit(`ScheduleWorks/SET_FETCH`, 'view')
          
          // await Store.dispatch(`${STORE}/getListWorks`, params);
          
          this.isDisableCheckBox = false
        }
      } else {
        this.items = [];
        this.itemsGroup = [];
        this.searchParams.total_page_group = 1;
        this.searchParams.current_page_group = 1;
        this.searchParams.current_page_all = 1;
        this.searchParams.total_page_all = 1;
      }
    },

    // 承認 / 承認解除
    async updateApprove(flag) {
      let ids = [];
      let params = {};
      if (flag == ACTION_APPROVE) {
        // approve
        if(this.ENV_OBAYASHI){
          ids = this.getSelectedItemApprove()
        } else {
          ids = this.getSelectedItems(ACTION_REJECT);
        }
      } else {
        // reject
        if(!this.ENV_OBAYASHI) {
          ids = this.getSelectedItems(ACTION_APPROVE);
        } else {
          ids = this.getSelectedItemsReject(ACTION_APPROVE);
        }
      }
      if (ids.length > 0) {
        let paramsRecords_plans = {};
        params = { work_result_ids: ids, work_approval_flag: flag };
        if(this.ENV_OBAYASHI){
          paramsRecords_plans.is_oba = 1;
          paramsRecords_plans.work_results = [params];
          paramsRecords_plans.approval_from_list = 1;
        } else {
          paramsRecords_plans = params;
        }
        const result = await Store.dispatch(
          `${STORE}/updateApproveWorks`,
          paramsRecords_plans
        );

        if (!result.hasError) {
          //成功したら値を更新
          let msg = "承認解除しました";
          if (flag == ACTION_APPROVE) {
            msg = "承認しました";
          }

          this.itemSelectedScroll = result.data.contents.entries.work_result_ids

          this.getItems('updateApprove');
          Store.dispatch("Notification/getNewNoticeStatus");
          Store.dispatch("Toast/show", {
            status: 200,
            message: msg,
          });
        }
      }
      this.closeApproveDialog();
    },

    async callApiCheckIsDirector(field_construction_id) {
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
          .company_user.user_id,
        field_construction_id,
      };
      this.ENV_OBAYASHI? params.is_check_approval = 1 : '';
      await Store.dispatch("Employees/checkIsDirector", { params });
    },

    // 削除
    async onRemove() {
      let ids = this.getSelectedItemsDelete(ACTION_APPROVE);
      let temp = { work_result_ids: ids };
      if (this.ENV_OBAYASHI) {
        temp["is_oba"] = 1;
      }
      const result = await Store.dispatch(`${STORE}/deleteWorks`, temp);

      if (!result.hasError) {
        this.searchParams["current_page_all"] = 1;
        this.searchParams["current_page_group"] = 1;
        //成功したら値を更新
        let entries = Object.entries(result.data.contents.entries.work_result_ids)

        entries.forEach(item => {
          if (item[1]) {
            const IDRecordUpdate = String(item[0]) == String(item[1]) ? item[1] : {
              delete: item[0],
              update: item[1]
            }
            this.itemSelectedScroll.push(IDRecordUpdate)
          }
          else this.listDeleteScroll.push(item[0])
        });
        
        this.getItems('remove');
        
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
      this.selectedItems = [];
      this.$refs.RecordsTablesGroupOba.selectedParent = []
      //this.reloadListAll = !this.reloadListAll;
      //this.reloadListGroup = !this.reloadListGroup;
      this.closeRemoveDialog();
    },

    /**
     * handleConfirm
     */
    handleConfirm() {
      if (this.dialogApproveText.approveFlg) {
        this.updateApprove(ACTION_APPROVE);
      } else {
        this.updateApprove(ACTION_REJECT);
      }
      this.selectedItems = [];
      this.$refs.RecordsTablesGroupOba.selectedParent = []
      //this.reloadListAll = !this.reloadListAll;
      //this.reloadListGroup = !this.reloadListGroup;
    },

    getSelectedItems(flag) {
      const ids = [];
      if(!this.ENV_OBAYASHI){
        this.itemsGroup.forEach((item) => {
          if (
            this.isSelected(item.work_result_id) &&
            item.work_approval_flag == flag && !item.state_handling_flag
          ) {
            ids.push(item.work_result_id);
          }
          // children
          item.children.forEach((child) => {
            if (
              this.isSelected(child.work_result_id) &&
              child.work_approval_flag == flag && !child.state_handling_flag
            ) {
              ids.push(child.work_result_id);
            }
          });
        });
      } else {
        this.itemsGroup.forEach((item) => {
          if (this.isSelected(item.work_result_id) && !item.state_handling_flag) {
            ids.push(item.work_result_id);
          }
          // children
          item.children.forEach((child) => {
            if (this.isSelected(child.work_result_id) && !child.state_handling_flag) {
              ids.push(child.work_result_id);
            }
          });
        });
      }
      return ids;
    },

    getSelectedItemApprove(){
      const ids = [];
      this.itemsGroup.forEach((item) => {
        if (this.isSelected(item.work_result_id) && !item.state_handling_flag && item.user_can_approve &&
        (item.dayworker_result != null)) {
          ids.push(item.work_result_id);
        }
        // children
        item.children.forEach((child) => {
          if (this.isSelected(child.work_result_id) && !child.state_handling_flag && child.user_can_approve &&
          (child.dayworker_result != null)) {
            ids.push(child.work_result_id);
          }
        });
      });
      return ids;
    },

    getSelectedItemsReject(flag){
      const ids = [];
      this.itemsGroup.forEach((item) => {
        if (this.isSelected(item.work_result_id) && !item.state_handling_flag && item.work_approval_flag == flag) {
          ids.push(item.work_result_id);
        }
        // children
        item.children.forEach((child) => {
          if (this.isSelected(child.work_result_id) && !child.state_handling_flag && child.work_approval_flag == flag) {
            ids.push(child.work_result_id);
          }
        });
      });
      return ids;
    },

    getSelectedItemsDelete(flag){
      const ids = [];
      this.itemsGroup.forEach((item) => {
          if (this.isSelected(item.work_result_id) && (item.state_handling_flag || item.work_approval_flag != flag)) {
            ids.push(item.work_result_id);
          }
          // children
          item.children.forEach((child) => {
            if (this.isSelected(child.work_result_id) && (child.state_handling_flag || child.work_approval_flag != flag)) {
              ids.push(child.work_result_id);
            }
          });
      });
      return ids;
    },

    openRemoveDialog() {
      let isApproved = null;
      if(!this.ENV_OBAYASHI) {
        const approves = this.getSelectedItems(ACTION_APPROVE);
        isApproved = approves.length == this.selectedItems.length;
      } else {
        isApproved = !this.getSelectedItemsDelete(ACTION_APPROVE).length;
      }
      if (isApproved) {
        this.dialogRemoveText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_RECORDS,
          text2: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_2,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_OK,
          isShowCloseBtn: false,
          isShowBtn: true,
        };
      } else {
        this.dialogRemoveText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_1,
          text2: DIALOG_REMOVE_TEXT.TEXT_2,
          text3: DIALOG_REMOVE_TEXT.TEXT_3,
          text4: DIALOG_REMOVE_TEXT.TEXT_4,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_SUBMIT,
          isShowCloseBtn: true,
          isShowBtn: true,
        };
      }
      this.popups.isShowRemoveDialog = true;
    },

    /**
     * closeRemoveDialog
     */
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.$refs.RecordsTablesGroupOba.selectedParent = []
        //this.reloadListAll = !this.reloadListAll;
        //this.reloadListGroup = !this.reloadListGroup;
        this.dialogRemoveText = {
          title: "",
          text1: "",
          text2: "",
          text3: null,
          text4: null,
          btnSubmitText: "",
          approveFlg: false,
          isShowCloseBtn: true,
        };
      });
      this.reloadList = !this.reloadList;
    },

    checkStateHandlingFlag(approveFlg) {
      let temp = approveFlg ? 1 : 0 ;
      let rs = true;
      this.itemsGroup.forEach((item) => {
        if (this.isSelected(item.work_result_id) && item.state_handling_flag == 0 && item.work_approval_flag != temp) {
          rs = false;
          return;
        }
        item.children.forEach((child) => {
          if (this.isSelected(child.work_result_id) && child.state_handling_flag == 0 && child.work_approval_flag != temp) {
            rs = false;
            return;
          }
        });
      });
      return rs;
    },

    /**
     * true: 承認解除
     * false: 承認
     */
    openConfirmDialog(approveFlg) {
      let isApproved = null;
      if(!this.ENV_OBAYASHI) {
        if (this.checkStateHandlingFlag(approveFlg)) {
          isApproved = true;
        } else {
          const approves = this.getSelectedItems(ACTION_APPROVE);
          isApproved = (approves.length == this.selectedItems.length && approveFlg) ||
            (approves.length == 0 && !approveFlg);
        }
      } else {
        if(approveFlg) {
          isApproved = this.checkStateApproval();
        } else {
         isApproved = this.selectedItems.length == this.getSelectedItemsDelete(ACTION_APPROVE).length;
        }
      }
      const {
        REJECT_BTN_SUBMIT,
        REJECT_TEXT2,
        REJECT_TEXT1,
        REJECT_TITLE,
        REJECT_ERROR_TEXT1,
        APPROVE_BTN_SUBMIT,
        APPROVE_TEXT2,
        APPROVE_TEXT1,
        APPROVE_TITLE,
        ALL_APPROVE_TTTLE,
        ALL_APPROVE_TEXT,
        BTN_OK,
        APPROVE_TEXT_OBA,
        APPROVE_TEXT1_OBA,
        APPROVE_TEXT2_OBA,
        APPROVE_TEXT3_OBA
      } = DIALOG_TEXT_RECORDS;
      if (isApproved) {
        if(!approveFlg) {
          this.dialogApproveText.title = REJECT_TITLE
          this.dialogApproveText.text1 = REJECT_ERROR_TEXT1
          this.dialogApproveText.isShowBtn = true;
          this.dialogApproveText.isShowCloseBtn = false;
          this.dialogApproveText.btnSubmitText = BTN_OK
        }else {
          if(this.ENV_OBAYASHI) {
            this.dialogApproveText.title = APPROVE_TEXT_OBA
            this.dialogApproveText.text1 = APPROVE_TEXT1_OBA
            this.dialogApproveText.text2 = APPROVE_TEXT2_OBA
            this.dialogApproveText.text3 = APPROVE_TEXT3_OBA
            this.dialogApproveText.isShowCloseBtn = true;
            this.dialogApproveText.isShowBtn = false;
            this.dialogApproveText.approveFlg = approveFlg
          } else {
            this.dialogApproveText.title = ALL_APPROVE_TTTLE
            this.dialogApproveText.text1 = ALL_APPROVE_TEXT
            this.dialogApproveText.text2 = null
            this.dialogApproveText.text3 = null;
            this.dialogApproveText.isShowBtn = true;
            this.dialogApproveText.btnSubmitText = BTN_OK
            this.dialogApproveText.approveFlg = !approveFlg
            this.dialogApproveText.isShowCloseBtn = false;
          }
        }
      } else {
        if(approveFlg) {
          if(this.ENV_OBAYASHI) {
            this.dialogApproveText.title = APPROVE_TEXT_OBA
            this.dialogApproveText.text1 = APPROVE_TEXT1_OBA
            this.dialogApproveText.text2 = APPROVE_TEXT2_OBA
            this.dialogApproveText.text3 = APPROVE_TEXT3_OBA
            this.dialogApproveText.isShowBtn = true;
            this.dialogApproveText.isShowCloseBtn = true;
            this.dialogApproveText.btnSubmitText = APPROVE_BTN_SUBMIT
            this.dialogApproveText.approveFlg = approveFlg
          } else {
            this.dialogApproveText.title = APPROVE_TITLE
            this.dialogApproveText.text1 = APPROVE_TEXT1
            this.dialogApproveText.text2 = APPROVE_TEXT2
            this.dialogApproveText.text3 = null;
            this.dialogApproveText.btnSubmitText = APPROVE_BTN_SUBMIT;
            this.dialogApproveText.isShowBtn = true;
            this.dialogApproveText.isShowCloseBtn = true;
            this.dialogApproveText.approveFlg = approveFlg
          }
        } else {
          this.dialogApproveText.title = REJECT_TITLE
          this.dialogApproveText.text1 = REJECT_TEXT1
          this.dialogApproveText.text2 = REJECT_TEXT2
          this.dialogApproveText.text3 = null;
          this.dialogApproveText.isShowBtn = true;
          this.dialogApproveText.btnSubmitText = REJECT_BTN_SUBMIT;
          this.dialogApproveText.isShowCloseBtn = true;
        }
      }
      this.popups.isShowConfirmDialog = true;
    },

    /**
     * closeApproveDialog
     */
    closeApproveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.$refs.RecordsTablesGroupOba.selectedParent = []
        //this.reloadListAll = !this.reloadListAll;
        //this.reloadListGroup = !this.reloadListGroup;
        this.dialogApproveText = {
          title: "",
          text1: "",
          text2: "",
          btnSubmitText: "",
          approveFlg: false,
        };
      });
      this.reloadList = !this.reloadList;
    },

    /**
     * Open dialog choose range date copy
     */
    openRangeDate () {
      this.popups.isShowPopupRangeDate = true;
    },

    /**
     * Close dialog choose range date copy
     */
    closeRangeDate () {
      this.popups.isShowPopupRangeDate = false;

      this.$nextTick(() => {
        this.selectedItems = [];
        this.$refs.RecordsTablesGroupOba.selectedParent = []
        //this.reloadListAll = !this.reloadListAll;
        //this.reloadListGroup = !this.reloadListGroup;
      });
      this.reloadList = !this.reloadList;
    },

    /**
     * handle Confirm Duplicate
     */
    handleConfirmDuplicate(work_date_start, work_date_end) {
      this.loadingCopyRecord = true
      this.popups.isShowPopupRangeDate = false;
      const plans = this.convertParamsCopyWorkPlans()
      this.copyWorkPlans(plans, work_date_start, work_date_end);

      this.selectedItems = [];
      this.$refs.RecordsTablesGroupOba.selectedParent = []
      //this.reloadListAll = !this.reloadListAll;
      //this.reloadListGroup = !this.reloadListGroup;
    },

    convertParamsCopyWorkPlans() {
      const result = [];

      const arrayFlat = this.itemsGroup.map(item => {
        const array = item.children.map(ele => {
          return ele.work_result_id
        })
        return [item.work_result_id, ...array]
      })

      for (let i = 0; i < arrayFlat.length; i++) {
        const filteredList = arrayFlat[i].filter(valueItem => this.selectedItems?.includes(valueItem));
        if (filteredList.length) result.push(filteredList)
      }

      return result
    },

    checkParentInArrayCopy(group) {
      const result = this.itemsGroup.find(children => {
         return children.work_result_id === group[0]
      })

      return result ? 1 : 0
    },

    async copyWorkPlans(groups, work_date_start, work_date_end) {
      const results = groups.map(item => {
        const newGroup = this.checkParentInArrayCopy(item)
        return {
          result_ids: item,
            is_1_group : newGroup
        }
      })

      const params = {
        results,
        work_date_start,
        work_date_end,
      };

      const resp = await Store.dispatch(`${STORE}/copyWorkPlans`, params);

      if (!resp.hasError) {
        this.getItems('onSearch');
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.loadingCopyRecord = false
    },

    checkStateApproval() {
      let rs = true;
      this.itemsGroup.forEach((item) => {
        if (this.isSelected(item.work_result_id) && !item.state_handling_flag && item.user_can_approve &&
        (item.dayworker_result != null)) {
          rs = false;
          return;
        }
        item.children.forEach((child) => {
          if (this.isSelected(child.work_result_id)  && !child.state_handling_flag && child.user_can_approve &&
          (child.dayworker_result !=null)) {
            rs = false;
            return;
          }
        });
      });
      return rs;
    },

    checkShowTypeDialog(){
      let rs = true;
      this.itemsGroup.forEach((item) => {
        if (this.isSelected(item.work_result_id) && !item.state_handling_flag) {
          rs = false;
          return;
        }
        item.children.forEach((child) => {
          if (this.isSelected(child.work_result_id) && !child.state_handling_flag) {
            rs = false;
            return;
          }
        });
      });
      return rs;
    },


    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      let _editedItem = { ...this.editedItem };
      const hasId = "id" in _editedItem;
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `${STORE}/update` : `${STORE}/post`,
        _editedItem
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        //成功したら値を更新
        if (hasId) {
          this.getItems('update');
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
    },

    /**
     *  (ユーザー用)
     * ユーザ権限名文字列取得
     * @param id:Number
     */
    getRollLabel(id) {
      return getRollLabelById(id);
    },

    /**
     *  (ユーザー用)
     * 日付変換
     * @param date:yyyy-mm-ddThh:mm:ss:z
     */
    getFormattedDate(date) {
      return fmtSlashDate(date);
    },


    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(id, isChecked) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedItems, id];
      } else {
        // unchecked
        _selectedItems = this.selectedItems.filter((item) => {
          return item != id;
        });
      }
      // 重複削除してset
      this.selectedItems = [...new Set(_selectedItems)];
    },

    checkSelectedParents(itemParent) {
      if (!this.isSelected(itemParent.work_result_id)) return false;
      for (let i = 0; i < itemParent.children.length; i++) {
        if (!this.isSelected(itemParent.children[i].work_result_id)) {
          return false;
        }
      }
      return true;
    },

    // checkboxの ON/OFFステータス
    isSelected(id) {
      return this.selectedItems.includes(id);
    },

    // 親にchild table追加
    async addNewChild(item) {
      if(!this.ENV_OBAYASHI) {
        if (item.field_tree_group_id) {
          this.workGroupResults["field_tree_group_id"] = item.field_tree_group_id;
        } else {
          this.workGroupResults["field_tree_group_id"] = null;
        }
        this.workGroupResults["work_group_result_id"] = item.work_group_result_id;
        this.workGroupResults["field_construction_id"] = item.field_construction_id;
        this.workGroupResults["work_hour_zone"] = item.work_hour_zone;
        this.workGroupResults["work_date"] = item.work_date;
        this.workGroupResults["addChild"] = true;
      } else {
        this.workGroupResults["work_group_result_id"] = item.work_group_result_id;
        this.workGroupResults["field_construction_id"] = item.field_construction_id;
        this.workGroupResults["work_hour_zone"] = item.work_hour_zone;
        this.workGroupResults["work_date"] = item.work_date;
        this.workGroupResults["job_id"] = item.job_id;
        this.workGroupResults["cmn_mst_skill_id"] = item.cmn_mst_skill_id;
        this.workGroupResults["addChild"] = true;
        this.workGroupResults["field_tree_id"] = item.field_tree_id;
        this.workGroupResults["field_tree_id_1"] = item.field_tree_id_1;
      }
      this.openNewItemForm(IS_CHILD_EDIT_TABLE);
    },

    async openPopupRatify(value){
      if(this.ENV_OBAYASHI){
        if(value.state_handling_flag == 0 && (value.dayworker_result != null)) {
          this.workResultApproval = {
            dayworker_type: value.dayworker_type,
            work_date: value.work_date,
            contents: value.contents,
            dayworker_plan: value.dayworker_result,
          }
          this.work_plan_id_choose = value.work_result_id;
          this.popups.isShowPopupRatify = true;
          this.state_handling_flag = value.state_handling_flag;
          await Store.dispatch(`ScheduleRecords/getListStatusApproval`, value.work_result_id);
        } else {
          const {
            APPROVE_TEXT_OBA,
            APPROVE_TEXT1_OBA,
            APPROVE_TEXT2_OBA,
            APPROVE_TEXT3_OBA,
          } = DIALOG_TEXT_RECORDS;
          this.dialogApproveText.isShowBtn = false
          this.dialogApproveText.title = APPROVE_TEXT_OBA;
          this.dialogApproveText.text1 =  APPROVE_TEXT1_OBA;
          this.dialogApproveText.text2 = APPROVE_TEXT2_OBA;
          this.dialogApproveText.text3 = APPROVE_TEXT3_OBA;
          this.dialogApproveText.isShowCloseBtn = true;
          this.dialogApproveText.approveFlg = true;
          this.popups.isShowConfirmDialog = true;
        }
      }
    },
    closePopupRatify(){
      this.popups.isShowPopupRatify = false;
    },

    async updateRafity(value){
      let params = [];
      let paramsRecords_plans = {};
      value.cancelList.length? params.push({
        work_result_approval_ids: value.cancelList,
        work_result_ids: [this.work_plan_id_choose],
        work_approval_flag: 0,
      }) : '';
      value.ratifyList.length? params.push({
        work_result_ids: [this.work_plan_id_choose],
        work_approval_flag: 1,
      }) : '';
      if(!params.length){
        this.popups.isShowPopupRatify = false;
        return;
      }
      if(this.ENV_OBAYASHI){
          paramsRecords_plans.is_oba = 1;
          paramsRecords_plans.work_results = params;
        } else {
          paramsRecords_plans = params;
        }
      const result = await Store.dispatch( `ScheduleRecords/updateApproveWorks`, paramsRecords_plans);
      if (result.hasError) {
        return;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.popups.isShowPopupRatify = false;
        this.itemSelectedScroll = result.data.contents.entries.work_result_ids

        this.getItems('updateApprove');
      }
    },
    resetFormSearch (event){
      event.preventDefault();
      this.isReset = !this.isReset;
      this.searchParams.field_tree_id = null;
      this.searchParams.dayworker_type = [];
      this.searchParams.work_approval_flag = [];
      this.searchParams.field_construction_id = Store.getters["PortalChart/getWorkTypes"][0].id;
      this.searchParams.group_id = null;
      this.searchParams.field_tree_id_lv1 = null;
      this.searchParams.self_approve_flg = this.ENV_OBAYASHI ? 0 : null;
      this.searchParams.field_item_tree_id = null;
      this.searchParams.work_user_plans_user_id = null;
      this.searchParams.other_level_1_only_flg = this.ENV_OBAYASHI ? 0 : null;
      this.searchParams.field_tree_user_id = null;
    },
    async getListSearchHistoryWorkResult() {
      await Store.dispatch(`${STORE}/getListSearchHistoryWorkResult`, {field_id: this.CURRENT_SITE.field_id})
    },
    openPopupCreateSearchHistory() {
      this.isShowCreateSearchHistory = true;
    },
    async createSearchHistory(name){
      if (this.checkStartEndDate()) {
        let searchParams = { ...this.searchParams };
        searchParams["start_work_date"] = searchParams["date"];
        searchParams["tab"] = SCHEDULE_TABLE_TAB_ITEMS.ALL.id;
        searchParams["end_work_date"] = searchParams["end_date"];

        if (this.ENV_OBAYASHI && !searchParams["self_approve_flg"]) {
          searchParams["self_approve_flg"] = 0
        }
        if (this.ENV_OBAYASHI && !searchParams["other_level_1_only_flg"]) {
          searchParams["other_level_1_only_flg"] = 0
        }
        searchParams["page_number"] = 1
        if (
          searchParams.start_work_date && searchParams.end_work_date &&
          searchParams.schedule_flag === SCHEDULE_FLAG.RECORD &&
          searchParams.field_construction_id
        ) {
          if (this.ENV_OBAYASHI) {
            searchParams["is_oba"] = 1;
          }
          this.deleteParams(searchParams);
          delete searchParams["start_work_date"]
          delete searchParams["end_work_date"]
          delete searchParams["work_hour_zone"]
          const jsonContents = JSON.stringify(searchParams);
          const params = {
            contents: jsonContents,
            name: name,
            field_id: this.CURRENT_SITE.field_id
          };
          await Store.dispatch(`${STORE}/createSearchHistoryWorkResult`, params).then((res) => {
            if(res.status == 200 || res.status == 201) {
              this.getListSearchHistoryWorkResult()
              Store.dispatch("Toast/show", {
                status: 200,
                message: "登録しました",
              });
            }
          }).catch(() => {}).finally(() => {
            this.isShowCreateSearchHistory = false;
          })
        }
      }
    },
    async seachByItemHistory(value) {
      let searchParams = {...value}
      await this.onChangeSearchParams({name: 'field_construction_id', value: searchParams.field_construction_id})
      await this.onChangeSearchParams({name: 'group_id', value: searchParams.group_id})
      await this.$refs.selectFieldTreeId.updateDataSelected(searchParams.field_tree_id)
      this.searchParams = {
        ...this.searchParams,
        ...searchParams
      }
      this.searchParams["current_page_all"] = 1;
      this.searchParams["current_page_group"] = 1;
      await this.getItems();
      this.selectedItems = [];
      this.reloadListAll = !this.reloadListAll;
      this.reloadListGroup = !this.reloadListGroup;
    },
    deleteItemsSeachHistory(id) {
      this.idItemSearchHistory = id
      this.isShowConfirmDeleteItemSearch = true
    },
    async clickConfirmDeleteItemSearch() {
      await Store.dispatch(`${STORE}/deleteSearchHistoryWorkResult`, {id: this.idItemSearchHistory}).then((res) => {
        if(res.data.result) {
          this.getListSearchHistoryWorkResult()
          Store.dispatch("Toast/show", {
            status: 200,
            message: "削除しました",
          });
        }
      }).catch(() => {}).finally(() => {
        this.isShowCreateSearchHistory = false;
        this.isShowConfirmDeleteItemSearch = false
      })
    },

    updateItems (value) {
      this.itemsGroup = _.cloneDeep(value)
    }
  },
};
</script>
<style lang="scss" scoped>
div.search-btn {
  height: 35px !important;
  padding-top: 22px;
}
::v-deep .wrapper__navi {
  width: 142px !important;
  justify-content: center !important
}
::v-deep .wrapper__navi .v-btn {
  width: 64px !important;
}
.select-position-multi .v-text-field {
  padding-top: 0;
  margin-top: 0;
}
</style>
